.experienceWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 2em 0;
}

.companyTitle {
    background-color: var(--deep-dark);
    padding: 0.2em;
    font-size: 1.5em;
    border-radius: 0.3em;
}

.years{
    padding: 0.2em;
    color: var(--gray-2);
}

.summary li {
    margin: 0.5em;
}