@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&family=Roboto:wght@300&display=swap');


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans', 'Open Sans', sans-serif;
}
:root {
  --dark-bg: #262626;
  --gray-1: #bcb4b4;
  --deep-dark: #1e1e1e;
  --gray-2: #a19f9f;
  --white: white;
  --black: black;
}
html {
  font-size: 10px;
  font-family: "Roboto Mono";
  background-color: var(--dark-bg);
  color: var(--white);
}

a {
  text-decoration: none;
  color: var(--white);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}