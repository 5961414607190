.aboutWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.aboutWrapper span.name {
  font-size: 4em;
  text-transform: uppercase;
  font-weight: bolder;
  transform: translateY(0.5em);
  z-index: 100;
  color: #d87093;
}

.aboutWrapper a, a::after{
  text-decoration: underline;
}

.aboutWrapper p.aboutMe {
  font-size: 2em;
}

.topWrapper,
.bottomWrapper {
  /* height: 80vh; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: space-around;
}

.aboutImage {
  margin-top: 3em;
  height: 60vh;
  width: auto;
  border-radius: 50%;
}

.aboutWrapper .heading {
  color: #d87093;
}

.expWrapper,
.skillsWrapper,
.imageWrapper {
  /* margin-top: 6em; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  /* width: 45%; */
}

.aboutMe {
  font-size: 1.5em;
}

.aboutMe > span {
  background-color: rgb(52,57,65);
  padding: 0.2em;
  border-radius: 0.3em;
}

@media screen and (max-width: 960px) {
  .aboutMe {
    font-size: 1.25em;
  }
}

@media screen and (max-width: 767px) {
  .topWrapper {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .bottomWrapper,
  .topWrapper {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .expWrapper,
  .skillsWrapper {
    width: 100%;
    padding: 0 5em;
    margin-top: 5em;
    /* height: 100vh; */
  }

  .imageWrapper {
    /* display: none; */
  }

  .aboutImage {
      height: 30vh;
  }
}

@media screen and (max-width: 440px) {
  .aboutWrapper {
    max-height: 420vh;
  }
}

@media screen and (min-width: 768px) {
  .bottomWrapper,
  .topWrapper {
    min-height: 80vh;
  }

  .aboutWrapper {
    height: 250vh;
  }
  .expWrapper,
  .skillsWrapper {
    width: 45%;
  }

  .aboutImage {
      margin-top: 0;
  }
}

@media screen and (max-width: 1024px) {
    .aboutImage {
        max-height: calc(min(50vh, 30em));
    }
    
}