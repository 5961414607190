@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&family=Roboto:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #262626;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --dark-bg: #262626;
  --gray-1: #bcb4b4;
  --deep-dark: #1e1e1e;
  --gray-2: #363636;
  --white: white;
  --black: black;
}

.navIcon p{
    color: #bcb4b4;
    color: var(--gray-1);
    font-size: 1.5em;
    padding-top: 2em;
    padding-right: 2em;

}

.navIcon {
    width: 100%;
    display: none;
}

.navlist {
  display: flex;
  justify-content: space-around;
  padding: 2em 20em;
}

.nav-link {
  color: white;
  color: var(--white);
  font-size: 2em;
  text-align: center;
}

.nav-link:hover {
  text-decoration: underline;
  color: palevioletred;
}

.isActive {
  text-decoration: underline;
}

@media screen and (max-width: 960px) {
  .navlist {
    padding: 2em 20em;
  }
}

@media screen and (max-width: 768px) {
  .navlist {
    padding: 2em 12em;
  }
}

@media screen and (max-width: 560px) {
  .navlist {
    background-color: #262626;
    background-color: var(--dark-bg);
    position: absolute;
    height: 80vh;
    flex-direction: column;
    z-index: 1000;
    justify-content: space-around;
    align-content: center;
    padding: auto;
    width: 100%;
  }
  .navIcon {
    display: flex;
    align-content: flex-end;
    justify-content: end;
  }
  .hideNavBar {
      display: none;
  }

}

.homeWrapper {
  padding-bottom: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeImage {
  height: 60vh;
  width: auto;
  align-self: center;
  margin: auto;
  margin-top: 0;
  opacity: 0.7;
  border: 1px solid white;
}

.homeWrapper h1 {
  font-size: 2em;
  letter-spacing: 2px;
  margin: auto;
  -webkit-transform: translateY(0.5em);
          transform: translateY(0.5em);
  margin-bottom: 0;
}

.homeWrapper p {
  font-size: 1.75em;
  font-weight: bold;
  margin: 0 14em;
  /* transform: translateY(-5em); */
  text-align: center;
  margin-bottom: 1em;
  line-height: 1.5;
  margin-top: 1em;
}

.homeWrapper span.name {
  font-size: 4em;
  text-transform: uppercase;
  font-weight: bolder;
  -webkit-transform: translateY(0.5em);
          transform: translateY(0.5em);
  z-index: 100;
}

.homeWrapper span.bits {
  color: palevioletred;
}

.resumeButton {
  background-color: var(--white);
  color: var(--deep-dark);
  font-weight: bolder;
  padding: 0.75em;
  outline: none;
  border-radius: 0.5em;
  border: none;
  cursor: pointer;
  /* transform: translateY(-5em); */
}

.resumeButton:hover {
  color: var(--white);
  background-color: var(--deep-dark);
}

@media screen and (max-width: 960px) {
  .homeWrapper p {
    margin: 0 8em;
  }
}

@media screen and (max-width: 768px) {
    .homeImage {
        height: 40vh;
    }
    .homeWrapper p {
        margin: 0 3em;
        margin-top: 0.5em;
    }
}

@media screen and (max-width: 440px) {
    .homeImage {
        /* display: none; */
        -webkit-transform: translateY(-5em);
                transform: translateY(-5em);
        margin-bottom: 0;
    }

    .homeWrapper h1, .homeWrapper span.name {
        -webkit-transform: translateY(1em);
                transform: translateY(1em);
        margin-top: 0;
        margin-bottom: 2em;
    }

    .homeWrapper h1{
        margin-bottom: 0;
    }

    .homeWrapper p{
      margin-bottom: 2em;
    }

    .homeWrapper {
      justify-content: flex-start;
    }
    
}

@media screen and (min-width: 440px) {
  .homeWrapper{
    height: 90vh;

  }
}
.skills {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
}

.skills img {
  max-height: 7.5vh;
  margin: 0;
  padding: 0;
}

.skillsTitle {
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 1.5em;
}

@media screen and (max-width: 600px) {
  .skills img {
    max-height: 5vh;
  }
}

@media screen and (max-width: 1024px) and (max-height:1400px) and (min-height: 700px){
  .skills img {
    max-height: calc(min(50px, 4vh));
  }
}

.aboutWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.aboutWrapper span.name {
  font-size: 4em;
  text-transform: uppercase;
  font-weight: bolder;
  -webkit-transform: translateY(0.5em);
          transform: translateY(0.5em);
  z-index: 100;
  color: #d87093;
}

.aboutWrapper a, a::after{
  text-decoration: underline;
}

.aboutWrapper p.aboutMe {
  font-size: 2em;
}

.topWrapper,
.bottomWrapper {
  /* height: 80vh; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: space-around;
}

.aboutImage {
  margin-top: 3em;
  height: 60vh;
  width: auto;
  border-radius: 50%;
}

.aboutWrapper .heading {
  color: #d87093;
}

.expWrapper,
.skillsWrapper,
.imageWrapper {
  /* margin-top: 6em; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  /* width: 45%; */
}

.aboutMe {
  font-size: 1.5em;
}

.aboutMe > span {
  background-color: rgb(52,57,65);
  padding: 0.2em;
  border-radius: 0.3em;
}

@media screen and (max-width: 960px) {
  .aboutMe {
    font-size: 1.25em;
  }
}

@media screen and (max-width: 767px) {
  .topWrapper {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .bottomWrapper,
  .topWrapper {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .expWrapper,
  .skillsWrapper {
    width: 100%;
    padding: 0 5em;
    margin-top: 5em;
    /* height: 100vh; */
  }

  .imageWrapper {
    /* display: none; */
  }

  .aboutImage {
      height: 30vh;
  }
}

@media screen and (max-width: 440px) {
  .aboutWrapper {
    max-height: 420vh;
  }
}

@media screen and (min-width: 768px) {
  .bottomWrapper,
  .topWrapper {
    min-height: 80vh;
  }

  .aboutWrapper {
    height: 250vh;
  }
  .expWrapper,
  .skillsWrapper {
    width: 45%;
  }

  .aboutImage {
      margin-top: 0;
  }
}

@media screen and (max-width: 1024px) {
    .aboutImage {
        max-height: calc(min(50vh, 30em));
    }
    
}
.experienceWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 2em 0;
}

.companyTitle {
    background-color: var(--deep-dark);
    padding: 0.2em;
    font-size: 1.5em;
    border-radius: 0.3em;
}

.years{
    padding: 0.2em;
    color: var(--gray-2);
}

.summary li {
    margin: 0.5em;
}
.contactWrapper {
    display: flex;
    height: 80vh;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.contactIconWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.contactIconWrapper a{
    color: var(--white);
    text-align: center;
    margin: 1em;
}

.contactIcons {
    height: 10vh;
    width: auto;
    font-size: 5vh;
    cursor: pointer;
}

.contactIcons:hover {
    color: palevioletred;
}

@media screen and (max-width: 540px) {
    .contactHeading {
        margin: 0 2em;
        text-align: center;
    }
}
.projectsWrapper {
  padding: 0;
  padding-top: 3em;
  max-width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
}

.pageWrapper {
  display: flex;
  flex-direction: column;
}

.projectsPageTitle {
  text-align: center;
  margin-top: 2em;
  color: palevioletred;
}
.projectImage{
    width: 60vh;
    height: auto;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    margin-bottom: 1em;
}

.projectImage:hover{
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
}

.projectCardWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 1em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 2em;
    flex-wrap: wrap;
}

.techIconWrapper, .techStackWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.gitLink {
    color: var(--white);
    height: 2em;
    padding-left: 0.5em;
}

.techstackIcon, .techIconWrapper img {
    height: 5vh;
    width: 5vh;
}

.projectCardWrapper p{
    max-width: 58vh;
    font-size: 1.5em;
}

@media screen and (max-width:540px) {
    .projectCardWrapper p{
        max-width: 80vw;
    }

    .projectImage {
        width: 80vw;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);

    }
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans', 'Open Sans', sans-serif;
}
:root {
  --dark-bg: #262626;
  --gray-1: #bcb4b4;
  --deep-dark: #1e1e1e;
  --gray-2: #a19f9f;
  --white: white;
  --black: black;
}
html {
  font-size: 10px;
  font-family: "Roboto Mono";
  background-color: #262626;
  background-color: var(--dark-bg);
  color: white;
  color: var(--white);
}

a {
  text-decoration: none;
  color: white;
  color: var(--white);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.footerText {
    font-size: 1.25em;
    color: var(--gray-2);
    text-align: center;
    margin: 2em;
}
