.projectsWrapper {
  padding: 0;
  padding-top: 3em;
  max-width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
}

.pageWrapper {
  display: flex;
  flex-direction: column;
}

.projectsPageTitle {
  text-align: center;
  margin-top: 2em;
  color: palevioletred;
}